<template>
  <el-dialog
    v-model="showTreasuryAccountMappingDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-treasury-account-mapping-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-treasury-account-mapping-dialog-header__title">
        {{ t('title.treasuryAccountMapping') }}
      </h4>
    </template>
    <div v-loading="dialogLoading" class="elv-treasury-account-mapping-content">
      <!-- default-expand-all -->
      <el-table
        ref="tableListRef"
        :data="listData"
        border
        height="650"
        row-key="rowKey"
        :expand-row-keys="expandRowKeys"
        :tree-props="{ children: 'list' }"
        class="elv-treasury-account-mapping-table"
        header-cell-class-name="elv-treasury-account-mapping-table-header__cell"
        header-row-class-name="elv-treasury-account-mapping-table-header"
        row-class-name="elv-treasury-account-mapping-table-row"
        cell-class-name="elv-treasury-account-mapping-table-row__cell"
        @row-click="onCellClicked"
        @expand-change="onExpandChange"
      >
        <el-table-column
          width="248"
          class-name="elv-treasury-account-mapping-table-row__platform"
          label-class-name="elv-treasury-account-mapping-table-row__platform-label"
        >
          <template #header>
            <div />
            <span>{{ t('common.platform') }}</span>
          </template>
          <template #default="{ row }">
            <div v-if="row.level === 0" class="elv-treasury-account-mapping-table-row__platform-type">
              <span>{{ row.platformType !== 'CHAIN' ? capitalize(row.platformType) : 'On-Chain' }}</span>
            </div>
            <span v-else-if="row.level === 1" class="elv-treasury-account-mapping-table-row__platform-currency-type">{{
              capitalize(row.currencyType)
            }}</span>
            <template v-else-if="row.level === 2">
              <div class="elv-treasury-account-mapping-table-row__platform-currency">
                <img :src="currencyData(row?.currency?.symbol).value?.logo" alt="" />
                <span>{{ currencyData(row?.currency?.symbol).value?.showSymbol }}</span>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :label="t('project.nav.chartOfCount')"
          class-name="elv-treasury-account-mapping-table-row__chartOfAccount"
        >
          <template #default="{ row, $index }">
            <el-select
              :ref="`chartOfAccountSelectRef${$index}`"
              v-model="row.chartOfAccount.chartOfAccountId"
              placeholder=" "
              popper-class="elv-treasury-account-mapping-chartOfAccount-popper"
              :class="{ 'is-inherit': row.level > 0 && row.inherit, 'is-show-arrow': row.showArrow }"
              @mouseenter="onMouseEnterChartOfAccount(row)"
              @mouseleave="onMouseLeaveChartOfAccount(row)"
              @change="onChangeChartOfAccount(row)"
            >
              <el-option
                v-for="(item, index) in chartOrAccountOptions"
                :key="index"
                :label="locale === 'en' ? item.name : item?.nameCN || item.name"
                :value="item.chartOfAccountId"
              />
              <template v-if="row.level > 0" #footer>
                <div @click="onFollowParentSetting(row, $index)">
                  <SvgIcon name="workflow-automation" fill="#1753EB" width="16" height="16" />
                  {{ t('button.followParentSetting') }}
                </div>
              </template>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { useComputedHook } from '@/hooks/useComputedHook'
import { useLedgerStore } from '@/stores/modules/ledger/index'
import { useTransactionStore } from '@/stores/modules/transactions'
import { find, map, capitalize, cloneDeep, isEmpty } from 'lodash-es'

const props = defineProps({
  chartOfAccountList: {
    type: Array<any>,
    default: () => {
      return []
    }
  }
})

const route = useRoute()
const { t, locale } = useI18n()
const instance: any = getCurrentInstance()
const ledgerStore = useLedgerStore()
const transactionStore = useTransactionStore()

const showTreasuryAccountMappingDialog = ref(false)
const dialogLoading = ref(false)
const listData: any = ref([])
const expandRowKeys: any = ref([])
const originalExpandRowKeys: any = ref([])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const chartOrAccountOptions = computed(() => {
  return !isEmpty(props.chartOfAccountList?.[0]?.list) ? cloneDeep(props.chartOfAccountList?.[0]?.list) : []
})
const currencyData: any = useComputedHook((symbol: string) => {
  return find(transactionStore.transactionCurrencyList, { symbol })
})

const findList = (row: any, showArrow: boolean) => {
  if (row?.list) {
    row.list?.forEach((item: any) => {
      if (item?.list) {
        item.showArrow = showArrow
        findList(item, showArrow)
      } else if (item.level === 2) {
        item.showArrow = showArrow
      }
    })
  }
}

const onMouseEnterChartOfAccount = (row: any) => {
  findList(row, true)
}

const onMouseLeaveChartOfAccount = (row: any) => {
  findList(row, false)
}

const fetchTreasuryAccountMappingList = async () => {
  try {
    dialogLoading.value = true
    await ledgerStore.fetchTreasuryAccountMappingList(entityId.value)
    listData.value = map(ledgerStore.treasuryAccountMappingList, (item) => {
      const list = map(item.currencyTypeList, (currencyTypeItem) => {
        const newList = map(currencyTypeItem.currencyList, (currencyItem) => {
          return {
            ...currencyItem,
            showArrow: false,
            rowKey: `2-${currencyItem.currency.symbol}-${item.platformType}`,
            level: 2
          }
        })
        return {
          ...currencyTypeItem,
          rowKey: `1-${currencyTypeItem.currencyType}-${item.platformType}`,
          level: 1,
          showArrow: false,
          list: newList
        }
      })
      expandRowKeys.value.push(`0-${item.platformType}`)
      return {
        ...item,
        level: 0,
        rowKey: `0-${item.platformType}`,
        list
      }
    })
    if (originalExpandRowKeys.value.length) {
      expandRowKeys.value = cloneDeep(originalExpandRowKeys.value)
    }
  } catch (error) {
    console.error(error)
  } finally {
    dialogLoading.value = false
  }
}

const onEditTreasuryAccountMapping = async () => {
  try {
    dialogLoading.value = true
    const params = map(listData.value, (item) => {
      const currencyTypeList = map(item.list, (currencyTypeItem) => {
        const currencyList = map(currencyTypeItem.list, (currencyItem) => {
          return {
            symbol: currencyItem.currency.symbol,
            chartOfAccountId: currencyItem.inherit ? '0' : currencyItem.chartOfAccount.chartOfAccountId
          }
        })
        return {
          currencyType: currencyTypeItem.currencyType,
          chartOfAccountId: currencyTypeItem.inherit ? '0' : currencyTypeItem.chartOfAccount.chartOfAccountId,
          currencyList
        }
      })
      return {
        platformType: item.platformType,
        chartOfAccountId: item.chartOfAccount.chartOfAccountId,
        currencyTypeList
      }
    })
    await LedgerApi.editTreasuryAccountMapping(entityId.value, params)
    ElMessage.success(t('message.editSuccess'))
    originalExpandRowKeys.value = cloneDeep(expandRowKeys.value)
    fetchTreasuryAccountMappingList()
  } catch (error) {
    dialogLoading.value = false
  }
}

const onExpandChange = (row: any, expanded: boolean) => {
  if (expanded) {
    expandRowKeys.value.push(row.rowKey)
  } else {
    expandRowKeys.value = expandRowKeys.value.filter((item: any) => item !== row.rowKey)
  }
}

const onChangeChartOfAccount = (row: any) => {
  if (row.level > 0) {
    // eslint-disable-next-line no-param-reassign
    row.inherit = false
  }
  onEditTreasuryAccountMapping()
}

const onFollowParentSetting = (row: any, index: number) => {
  instance.refs?.[`chartOfAccountSelectRef${index}`]?.blur()
  // eslint-disable-next-line no-param-reassign
  row.inherit = true
  onEditTreasuryAccountMapping()
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = (row: any, column: any, e: any) => {
  console.log(row, column)
}

const onCheckTreasuryAccountMappingDialog = () => {
  showTreasuryAccountMappingDialog.value = !showTreasuryAccountMappingDialog.value
}

const onCloseDialog = () => {}

defineExpose({ onCheckTreasuryAccountMappingDialog })

watch(
  [() => showTreasuryAccountMappingDialog.value],
  async () => {
    if (showTreasuryAccountMappingDialog.value) {
      dialogLoading.value = true
      expandRowKeys.value = []
      originalExpandRowKeys.value = []
      fetchTreasuryAccountMappingList()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-treasury-account-mapping-dialog {
  width: 700px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-treasury-account-mapping-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 40px;
    width: 700px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-treasury-account-mapping-content {
      width: 100%;
    }
  }

  .elv-treasury-account-mapping-table {
    --el-table-border-color: #d4dce5;
    --el-table-border: 1px solid #edf0f3;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d4dce6;
    border-bottom-width: 1px;

    .el-table__inner-wrapper {
      width: 100%;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    &.el-table--border {
      border-bottom-color: #edf0f3;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    .el-table__body-wrapper {
      height: 616px;

      .el-scrollbar__view {
        width: 100%;
        margin-bottom: 0px !important;
      }
    }

    .el-table__border-left-patch {
      width: 0;
    }

    .elv-treasury-account-mapping-table-header {
      background: #eef4fb;

      .elv-treasury-account-mapping-table-header__cell {
        background: #eef4fb;
        height: 36px;
        box-sizing: border-box;
        border-right-color: #e4e7eb;
        border-bottom-color: #ced7e0;
        padding: 0px;

        &:last-child {
          border-right: 0px;
        }

        &.is-center {
          .cell {
            justify-content: center;
          }
        }

        &.elv-treasury-account-mapping-table-row__platform-label {
          .cell {
            padding-left: 0px;
            height: 100%;

            > div {
              width: 34px;
              height: 100%;
              // box-sizing: border-box;
              border-right: 1px solid #e4e7eb;
              margin-right: 10px;
            }
          }
        }

        .cell {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #666d74;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }

    .el-table__row.elv-treasury-account-mapping-table-row {
      background-color: #fff;

      .elv-treasury-account-mapping-table-row__chartOfAccount {
        .cell {
          padding: 0px;

          .el-select.is-inherit .el-select__wrapper {
            .el-select__selected-item.el-select__placeholder {
              color: #838d95;
            }
          }

          .el-select.is-show-arrow .el-select__wrapper {
            .el-select__suffix {
              display: flex;
            }
          }

          .el-select__wrapper {
            box-shadow: none;
            border-width: 0px;
            padding-left: 0px;
            border-radius: 0px;

            &:hover,
            &.is-focused {
              background-color: #edf0f3 !important;
            }

            .el-select__suffix {
              display: none;
              transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            &:hover {
              .el-select__suffix {
                display: flex;
              }
            }

            .el-select__selected-item.el-select__placeholder {
              color: #1e2024;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;

              span {
                padding-left: 12px;
              }
            }
          }
        }
      }

      &.el-table__row--level-0 .elv-treasury-account-mapping-table-row__cell,
      .elv-treasury-account-mapping-table-row__cell {
        &.elv-treasury-account-mapping-table-row__platform {
          background-color: #fcf3e3;

          .cell {
            padding-right: 0px;
          }

          .elv-treasury-account-mapping-table-row__platform-type {
            padding-left: 10px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-left: 1px solid #edf0f3;
            z-index: 20;

            span {
              border-radius: 2px;
              border: 1px solid #ffd89e;
              background: #ffe5be;
              height: 24px;
              display: flex;
              padding: 0px 8px;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              color: #0e0f11;
              font-family: 'Plus Jakarta Sans';
              font-size: 13px;
              font-style: normal;
              font-weight: 700;
              line-height: 13px;
            }
          }
        }

        span {
          padding-left: 10px;
        }
      }

      &.el-table__row--level-1 {
        & + .el-table__row--level-2 .elv-treasury-account-mapping-table-row__platform {
          border-bottom-width: 0px;

          .elv-treasury-account-mapping-table-row__platform-currency {
            border-bottom: 1px solid #edf0f3;
          }
        }

        .elv-treasury-account-mapping-table-row__cell {
          background-color: #fff;

          &.elv-treasury-account-mapping-table-row__chartOfAccount {
            .cell {
              font-weight: 500;
            }
          }

          &.elv-treasury-account-mapping-table-row__platform {
            background-color: #fff;

            .elv-treasury-account-mapping-table-row__platform-currency-type {
              color: #0e0f11;
              font-family: 'Plus Jakarta Sans';
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }
      }

      &.el-table__row--level-2 {
        .elv-treasury-account-mapping-table-row__cell {
          background-color: #fff;

          &.elv-treasury-account-mapping-table-row__chartOfAccount {
            .cell {
              font-weight: 500;
            }

            .el-select__wrapper {
              background-color: #f9fafb;
            }
          }

          &.elv-treasury-account-mapping-table-row__platform {
            .cell {
              padding-right: 0px;
            }

            .elv-treasury-account-mapping-table-row__platform-currency {
              width: 214px;
              height: 100%;
              padding-left: 10px;
              box-sizing: border-box;
              z-index: 20;
              border-left: 1px solid #edf0f3;
              background-color: #f9fafb;
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                display: block;
                margin-right: 8px;
                border-radius: 50%;
              }

              span {
                color: #0e0f11;
                font-family: 'Plus Jakarta Sans';
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-left: 0px;
                padding-left: 0px;
              }
            }

            .el-table__expand-icon {
              background-color: #fff;

              .el-icon {
                display: none;
              }
            }
          }
        }
      }
    }

    .elv-treasury-account-mapping-table-row__cell {
      padding: 0;
      height: 42px;

      &:last-child {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.elv-treasury-account-mapping-table-row__platform {
        height: 42px;

        .cell {
          display: flex;
          padding-left: 0px;
          box-sizing: border-box;
          align-items: center;
          height: 100%;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          .el-table__indent {
            padding-left: 0px !important;
            border-left: 0px;
          }

          .el-table__placeholder {
            width: 34px;
            box-sizing: border-box;
            height: 100%;
            padding-left: 34px !important;
            border-left: 0px;
          }

          span {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #edf0f3;
            white-space: nowrap;
          }

          .el-table__expand-icon {
            min-width: 34px;
            width: 34px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            margin-right: 0px;
          }
        }
      }

      .cell {
        padding: 0 10px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #0e0f11;
        line-height: 16px;
      }

      &.el-table-column--selection {
        .cell {
          width: 100%;
          padding: 0px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-treasury-account-mapping-chartOfAccount-popper.el-popper {
  .el-select-dropdown__footer {
    padding: 4px;
    height: 44px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    div {
      cursor: pointer;
      height: 36px;
      padding: 4px 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Plus Jakarta Sans';
      color: #1753eb;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      svg {
        margin-right: 10px;
      }
    }
  }
}
</style>
