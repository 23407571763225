<template>
  <el-dialog
    v-model="showDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-account-mapping-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-account-mapping-dialog-header__title">
        {{ t('title.accountMapping') }}
      </h4>
    </template>
    <div class="elv-account-mapping-dialog-tabs" @click="onChangeTab($event)">
      <div
        class="elv-account-mapping-dialog-tab__pane"
        :class="{ 'is-active': activeTab === 'EXPORT' }"
        aria-controls="EXPORT"
      >
        {{ t('button.exportFromElven') }}
      </div>
      <div
        class="elv-account-mapping-dialog-tab__pane"
        :class="{ 'is-active': activeTab === 'IMPORT' }"
        aria-controls="IMPORT"
      >
        {{ t('button.importToElven') }}
      </div>
      <div v-if="activeTab === 'IMPORT'" class="elv-account-mapping-dialog-tab__addNew" @click="onAddNewImportMapping">
        <SvgIcon name="source-add" width="14" height="14" fill="#838D95" />
        {{ t('button.addNew') }}
      </div>
    </div>
    <div v-loading="dialogLoading" class="elv-account-mapping-content">
      <el-table
        ref="tableListRef"
        :data="tableListData"
        border
        row-key="rowKey"
        class="elv-account-mapping-table"
        header-cell-class-name="elv-account-mapping-table-header__cell"
        header-row-class-name="elv-account-mapping-table-header"
        row-class-name="elv-account-mapping-table-row"
        cell-class-name="elv-account-mapping-table-row__cell"
      >
        <template v-if="activeTab === 'EXPORT'">
          <el-table-column
            width="90"
            class-name="elv-account-mapping-table-row__platform"
            label-class-name="elv-account-mapping-table-row__platform-label"
          >
            <template #default="{ row }">
              <div v-if="row.thirdpartyPlatform === 'XERO'" class="elv-account-mapping-table-row__platform-logo">
                <img src="@/assets/img/xero.png" alt="Xero" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="t('common.platform')"
            class-name="elv-account-mapping-table-row__platform"
            label-class-name="elv-account-mapping-table-row__platform-label"
          >
            <template #default="{ row }">
              <div v-if="row.thirdpartyPlatform === 'XERO'" class="elv-account-mapping-table-row__platform-name">
                Xero
              </div>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          v-else
          :label="t('common.name')"
          class-name="elv-account-mapping-table-row__platform"
          label-class-name="elv-account-mapping-table-row__platform-label"
        >
          <template #default="{ row }">
            <div class="elv-account-mapping-table-row__platform-name">{{ row?.name }}</div>
          </template>
        </el-table-column>
        <el-table-column
          :width="activeTab === 'EXPORT' ? 314 : 274"
          :label="t('title.chartOfAccounts')"
          class-name="elv-account-mapping-table-row__platform"
          label-class-name="elv-account-mapping-table-row__platform-label"
        >
          <template #default="{ row }">
            <div class="account-mapping-list-coa">
              <div v-if="row?.entityFile || row?.lastEntityFile" class="account-mapping-list-coa-status">
                <SvgIcon name="success-mapping" width="14" height="14" />
                <b>{{ t('button.uploaded') }}:</b>
                <span>
                  {{
                    dayjs
                      .tz(
                        activeTab === 'EXPORT' ? row?.entityFile?.createdAt : row?.lastEntityFile?.createdAt,
                        entityStore.entityDetail?.timezone ?? 'UTC'
                      )
                      .format('YYYY/MM/DD HH:mm')
                  }}&nbsp;{{ currentEntityTimezone }}
                </span>
              </div>
              <div class="account-mapping-list-coa-upload" @click="onUploadNewVersion(row)">
                <SvgIcon name="source-upload" width="14" height="14" />
                <span>{{
                  row.entityFile || row?.lastEntityFile ? t('button.uploadNewVersion') : t('button.upload')
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="t('title.mapping')"
          width="140"
          header-align="center"
          class-name="elv-account-mapping-table-row__platform"
          label-class-name="elv-account-mapping-table-row__platform-label"
        >
          <template #default="{ row }">
            <div class="elv-account-mapping-table-row__platform-edit-button">
              <elv-button
                type="primary"
                :disabled="!(row?.entityFile?.entityFileId || row?.lastEntityFile?.entityFileId)"
                @click="onOpenAccountMappingDialog(row)"
              >
                {{ t('title.editMapping') }}
              </elv-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="activeTab === 'IMPORT'"
          label=""
          width="70"
          class-name="elv-account-mapping-table-row__operating"
          align="center"
        >
          <template #default="{ row }">
            <SvgIcon name="sources-edit" width="18" height="18" fill="#838D95" @click="onEditAccountMapping(row)" />
            <SvgIcon name="sources-delete" width="18" height="18" fill="#838D95" @click="onDeleteAccountMapping(row)" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
  <UploadCSVDialog
    ref="uploadCSVDialogRef"
    :title="activeTab === 'EXPORT' ? t('button.uploadXeroCOA') : t('title.uploadChartOfAccount')"
    :templateSlug="templateSlug"
    :showAnalyze="activeTab !== 'EXPORT'"
    :showSuccess="activeTab === 'EXPORT'"
    :submitText="uploadCSVSubmitText"
    :templateUrl="activeTab === 'IMPORT' ? 'file/csv/Elven%20ChartOfAccounts%20Template.csv' : ''"
    :noPermission="['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.journal?.create"
    @onSaveImport="onSaveImport"
    @onResetList="fetchChartOfAccountMappingList"
  />

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :show-cancel-button="true"
    :confirm-button-text="t('button.delete')"
    :cancel-button-text="t('button.cancel')"
    :title="t('title.deleteAccountMapping')"
    :loading="deleteLoading"
    @onConfirmEvent="onConfirmDeleteAccountMapping"
    @onCancelEvent="onCancelDeleteAccountMapping"
  >
    <template #content>
      <span class="elv-confirm-info-header__title balance">{{
        t('message.deleteChartMessage', { name: currentData?.name })
      }}</span></template
    >
  </ElvMessageBox>

  <ChartOfAccountMappingEditDialog
    ref="chartOfAccountMappingEditDialogRef"
    :type="activeTab"
    :importMappingData="currentData"
    :model="importMappingModel"
    @onResetList="fetchChartOfAccountMappingList"
    @onOpenAccountMappingNameDialog="onOpenAccountMappingNameDialog"
  />

  <AccountMappingNameSetDialog
    ref="accountMappingNameSetDialogRef"
    :currentData="currentData"
    :model="importMappingModel"
    :importMappingData="importMappingData"
    @resetList="fetchChartOfAccountImportMappingList"
  />
</template>

<script setup lang="ts">
import {
  ChartOfAccountThirdpartyPlatformType,
  ChartOfAccountImportMappingGroupType,
  ChartOfAccountImportMappingParamsType,
  ChartOfAccountImportMappingGroupParamsType
} from '#/LedgerTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import timezoneList from '@/config/timezone'
import timezone from 'dayjs/plugin/timezone'
import SvgIcon from '@/components/Base/SvgIcon.vue'
import { useEntityStore } from '@/stores/modules/entity'
import AccountMappingNameSetDialog from './AccountMappingNameSetDialog.vue'
import ChartOfAccountMappingEditDialog from './ChartOfAccountMappingEditDialog.vue'
import UploadCSVDialog from '@/pages/Financials/Project/components/UploadCSVDialog.vue'

dayjs.extend(utc)
dayjs.extend(timezone)

const route = useRoute()
const { t } = useI18n()
const showDialog = ref(false)
const dialogLoading = ref(false)
const deleteLoading = ref(false)
const uploadCSVDialogRef = ref()
const deleteMessageBoxRef = ref()
const chartOfAccountMappingEditDialogRef = ref()
const accountMappingNameSetDialogRef = ref()
const importMappingData = ref<ChartOfAccountImportMappingGroupParamsType>(
  {} as ChartOfAccountImportMappingGroupParamsType
)
const activeTab = ref('EXPORT')
const importMappingModel = ref('add')
const chartOfAccountThirdpartyPlatformList = ref<ChartOfAccountThirdpartyPlatformType[]>([])
const chartOfAccountImportMappingGroupList = ref<ChartOfAccountImportMappingGroupType[]>([])
const currentData = ref<ChartOfAccountImportMappingGroupType>({} as ChartOfAccountImportMappingGroupType)
const entityStore = useEntityStore()

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const templateSlug = computed(() => {
  return activeTab.value === 'EXPORT' ? 'thirdparty-chart-of-account-xero' : 'thirdparty-import-chart-of-account'
})

const uploadCSVSubmitText = computed(() => {
  if (activeTab.value === 'EXPORT') return t('common.done')
  if (importMappingModel.value === 'add') return t('button.next')
  return t('button.import')
})

const tableListData = computed(() => {
  return activeTab.value === 'EXPORT'
    ? chartOfAccountThirdpartyPlatformList.value
    : chartOfAccountImportMappingGroupList.value
})

const currentEntityTimezone = computed(() => {
  return timezoneList.find((item) => item.area === entityStore.entityDetail?.timezone)?.timezone ?? 'UTC+00:00'
})

/**
 * @description: 点击上传文件按钮打开弹窗
 * @param {ChartOfAccountImportMappingGroupType} row

 */
const onUploadNewVersion = (row: ChartOfAccountImportMappingGroupType) => {
  if (activeTab.value === 'IMPORT') {
    currentData.value = row
    importMappingModel.value = 'edit'
  }
  uploadCSVDialogRef.value?.onCheckUploadCSVDialog()
}

const onAddNewImportMapping = () => {
  importMappingModel.value = 'add'
  currentData.value = {} as ChartOfAccountImportMappingGroupType
  uploadCSVDialogRef.value?.onCheckUploadCSVDialog()
}

/**
 * @description: 获取第三方COA的平台映射列表
 */
const fetchThirdpartyChartOfAccountPlatformMappingList = async () => {
  try {
    dialogLoading.value = true
    const { data } = await LedgerApi.getThirdpartyChartOfAccountPlatformMappingList(entityId.value)
    chartOfAccountThirdpartyPlatformList.value = data
  } catch (error) {
    console.log(error)
  } finally {
    dialogLoading.value = false
  }
}

/**
 * @description: 获取导入Elven映射列表
 */
const fetchChartOfAccountImportMappingList = async () => {
  try {
    dialogLoading.value = true
    const { data } = await LedgerApi.getChartOfAccountImportMappingList(entityId.value)
    chartOfAccountImportMappingGroupList.value = data
  } catch (error) {
    console.log(error)
  } finally {
    dialogLoading.value = false
  }
}

/**
 * @description: 获取科目映射列表
 */
const fetchChartOfAccountMappingList = () => {
  if (activeTab.value === 'IMPORT') {
    fetchChartOfAccountImportMappingList()
  } else {
    fetchThirdpartyChartOfAccountPlatformMappingList()
  }
}

/**
 * @description: 科目映射关联csv文件
 */
const onSaveImport = async (params: any) => {
  try {
    let res
    if (activeTab.value === 'EXPORT') {
      res = await LedgerApi.relateThirdpartyChartOfAccountCsv(entityId.value, 'XERO', params)
      uploadCSVDialogRef.value?.onSuccessImport()
      fetchChartOfAccountMappingList()
    } else if (importMappingModel.value === 'add') {
      importMappingData.value.entityFileId = params.entityFileId
      currentData.value.lastEntityFile = {
        entityFileId: params.entityFileId,
        name: params.name
      } as any
      chartOfAccountMappingEditDialogRef.value?.onCheckDialog()
      uploadCSVDialogRef.value?.onCheckUploadCSVDialog()
    } else {
      res = await LedgerApi.editChartOfAccountImportMapping(
        entityId.value,
        currentData.value?.chartOfAccountImportMappingGroupId,
        {
          entityFileId: params.entityFileId
        }
      )
      uploadCSVDialogRef.value?.onSuccessImport()
      fetchChartOfAccountMappingList()
    }
    return Promise.resolve(res)
  } catch (error: any) {
    ElMessage.error(error.message)
    return Promise.reject(error)
  } finally {
    uploadCSVDialogRef.value?.onFinallyImport()
  }
}

/**
 * @description: 切换弹窗显示状态
 */
const onCheckDialog = () => {
  showDialog.value = !showDialog.value
}

/**
 * @description: 取消删除导入科目映射
 */
const onCancelDeleteAccountMapping = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description: 删除导入科目映射
 */
const onConfirmDeleteAccountMapping = async () => {
  try {
    deleteLoading.value = true
    await LedgerApi.deleteChartOfAccountImportMapping(
      entityId.value,
      currentData.value?.chartOfAccountImportMappingGroupId
    )
    onCancelDeleteAccountMapping()
    ElMessage.success(t('message.deleteSuccess'))
    fetchChartOfAccountImportMappingList()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

/**
 * @description: 打开编辑导入科目映射弹窗
 * @param {ChartOfAccountImportMappingGroupType} row
 */
const onOpenAccountMappingDialog = (row: ChartOfAccountImportMappingGroupType) => {
  if (activeTab.value === 'IMPORT') {
    currentData.value = row
    importMappingModel.value = 'edit'
  }
  chartOfAccountMappingEditDialogRef.value?.onCheckDialog()
}

/**
 * @description: 打开编辑科目映射名称弹窗
 * @param {ChartOfAccountImportMappingParamsType[]} chartOfAccountImportMapping
 */
const onOpenAccountMappingNameDialog = (chartOfAccountImportMapping: ChartOfAccountImportMappingParamsType[]) => {
  importMappingData.value.chartOfAccountImportMapping = chartOfAccountImportMapping
  accountMappingNameSetDialogRef.value?.onCheckDialog()
}

/**
 * @description: 打开编辑科目映射名称弹窗
 * @param {ChartOfAccountImportMappingGroupType} row
 */
const onEditAccountMapping = (row: ChartOfAccountImportMappingGroupType) => {
  currentData.value = row
  accountMappingNameSetDialogRef.value?.onCheckDialog()
}

/**
 * @description: 打开删除科目映射确认框
 * @param {ChartOfAccountImportMappingGroupType} row
 */
const onDeleteAccountMapping = (row: ChartOfAccountImportMappingGroupType) => {
  currentData.value = row
  onCancelDeleteAccountMapping()
}

/**
 * @description: 切换tab
 * @param {Event} e
 */
const onChangeTab = (e: Event) => {
  const { target } = e
  const tab = (target as HTMLElement)?.getAttribute('aria-controls')
  if (tab) {
    activeTab.value = tab
    dialogLoading.value = true
    chartOfAccountThirdpartyPlatformList.value = []
    chartOfAccountImportMappingGroupList.value = []
    fetchChartOfAccountMappingList()
  }
}

/**
 * @description: 关闭弹窗
 */
const onCloseDialog = () => {
  activeTab.value = 'EXPORT'
  chartOfAccountThirdpartyPlatformList.value = []
  chartOfAccountImportMappingGroupList.value = []
}

watch(
  () => showDialog.value,
  () => {
    if (showDialog.value) {
      fetchChartOfAccountMappingList()
    }
  },
  { immediate: true }
)

defineExpose({ onCheckDialog })
</script>

<style lang="scss">
.elv-account-mapping-dialog {
  width: 709px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-mapping-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 40px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-account-mapping-content {
      width: 100%;
    }
  }

  .elv-account-mapping-dialog-tabs {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    gap: 16px;
    flex: 1 0 0;
    flex-wrap: wrap;
    margin-bottom: 10px;
    position: relative;

    .elv-account-mapping-dialog-tab__pane {
      display: flex;
      height: 27px;
      padding: 0px 16px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      border: 1px solid #e5edff;
      background: #fff;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      transition: all 0.1s;
      white-space: nowrap;

      &:not(.is-active):hover {
        border: 1px solid #5e85eb;
      }

      &.is-active {
        background: #1753eb;
        color: #fff;
        border: 1px solid #1753eb;
      }
    }

    .elv-account-mapping-dialog-tab__addNew {
      display: flex;
      height: 30px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      border-radius: 22px;
      background: #f9fafb;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.1s;

      &:hover {
        color: #0e0f11;
        background: #edf0f3;

        svg {
          fill: #636b75;
        }
      }

      svg {
        transition: 0.1s;
        margin-right: 8px;
      }
    }
  }

  .elv-account-mapping-table {
    --el-table-border-color: #d4dce5;
    --el-table-border: 1px solid #edf0f3;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d4dce6;
    border-bottom-width: 1px;
    border-radius: 3px;

    .el-table__inner-wrapper {
      width: 100%;

      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    &.el-table--border {
      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }

    .el-table__body-wrapper {
      height: 616px;

      .el-scrollbar__view {
        width: 100%;
        margin-bottom: 0px !important;
      }
    }

    .el-table__border-left-patch {
      width: 0;
    }

    .elv-account-mapping-table-header {
      background: #eef4fb;

      .elv-account-mapping-table-header__cell {
        background: #eef4fb;
        height: 36px;
        box-sizing: border-box;
        border-right-color: #e4e7eb;
        border-bottom-color: #ced7e0;
        padding: 0 0 0 10px;

        &:last-child {
          border-right: 0px;
        }

        &.is-center {
          .cell {
            justify-content: center;
          }
        }

        &.elv-account-mapping-table-row__platform-label {
          .cell {
            padding-left: 0px;
            height: 100%;

            > div {
              width: 34px;
              height: 100%;
              // box-sizing: border-box;
              border-right: 1px solid #e4e7eb;
              margin-right: 10px;
            }
          }
        }

        .cell {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #666d74;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }

    .el-table__row.elv-account-mapping-table-row {
      background-color: #fff;

      &:last-of-type {
        .el-table__cell {
          border-bottom: 0px;
        }
      }

      .elv-account-mapping-table-row__chartOfAccount {
        .cell {
          padding: 0px;

          .el-select.is-inherit .el-select__wrapper {
            .el-select__selected-item.el-select__placeholder {
              color: #838d95;
            }
          }

          .el-select.is-show-arrow .el-select__wrapper {
            .el-select__suffix {
              display: flex;
            }
          }

          .el-select__wrapper {
            box-shadow: none;
            border-width: 0px;
            padding-left: 0px;
            border-radius: 0px;

            &:hover,
            &.is-focused {
              background-color: #edf0f3 !important;
            }

            .el-select__suffix {
              display: none;
              transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            &:hover {
              .el-select__suffix {
                display: flex;
              }
            }

            .el-select__selected-item.el-select__placeholder {
              color: #1e2024;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;

              span {
                padding-left: 12px;
              }
            }
          }
        }
      }

      &.el-table__row--level-0 .elv-account-mapping-table-row__cell,
      .elv-account-mapping-table-row__cell {
        &.elv-account-mapping-table-row__platform {
          // background-color: #fcf3e3;

          .cell {
            padding-right: 0px;
          }

          .elv-account-mapping-table-row__platform-type {
            padding-left: 10px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-left: 1px solid #edf0f3;
            z-index: 20;

            span {
              border-radius: 2px;
              border: 1px solid #ffd89e;
              background: #ffe5be;
              height: 24px;
              display: flex;
              padding: 0px 8px;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              color: #0e0f11;
              font-family: 'Plus Jakarta Sans';
              font-size: 13px;
              font-style: normal;
              font-weight: 700;
              line-height: 13px;
            }
          }
        }

        &.elv-account-mapping-table-row__operating .cell {
          display: flex;
          align-items: center;
          gap: 8px;

          svg {
            fill: #838d95;

            &.is-disabled {
              cursor: not-allowed !important;
            }

            &:not(.is-disabled):hover {
              cursor: pointer;
              fill: #1e2024;
            }
          }
        }
      }

      &.el-table__row--level-1 {
        & + .el-table__row--level-2 .elv-account-mapping-table-row__platform {
          border-bottom-width: 0px;

          .elv-account-mapping-table-row__platform-currency {
            border-bottom: 1px solid #edf0f3;
          }
        }

        .elv-account-mapping-table-row__cell {
          background-color: #fff;

          &.elv-account-mapping-table-row__chartOfAccount {
            .cell {
              font-weight: 500;
            }
          }

          &.elv-account-mapping-table-row__platform {
            background-color: #fff;

            .elv-account-mapping-table-row__platform-currency-type {
              color: #0e0f11;
              font-family: 'Plus Jakarta Sans';
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }
      }

      &.el-table__row--level-2 {
        .elv-account-mapping-table-row__cell {
          background-color: #fff;

          &.elv-account-mapping-table-row__chartOfAccount {
            .cell {
              font-weight: 500;
            }

            .el-select__wrapper {
              background-color: #f9fafb;
            }
          }

          &.elv-account-mapping-table-row__platform {
            .cell {
              padding-right: 0px;
            }

            .elv-account-mapping-table-row__platform-currency {
              width: 214px;
              height: 100%;
              padding-left: 10px;
              box-sizing: border-box;
              z-index: 20;
              border-left: 1px solid #edf0f3;
              background-color: #f9fafb;
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                display: block;
                margin-right: 8px;
                border-radius: 50%;
              }

              span {
                color: #0e0f11;
                font-family: 'Plus Jakarta Sans';
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-left: 0px;
                padding-left: 0px;
              }
            }

            .el-table__expand-icon {
              background-color: #fff;

              .el-icon {
                display: none;
              }
            }
          }
        }
      }
    }

    .elv-account-mapping-table-row__cell {
      padding: 0;
      height: 42px;

      &:last-child {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.elv-account-mapping-table-row__platform {
        height: 42px;

        .cell {
          display: flex;
          padding-left: 0px;
          box-sizing: border-box;
          align-items: center;
          height: 100%;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          .el-table__indent {
            padding-left: 0px !important;
            border-left: 0px;
          }

          .el-table__placeholder {
            width: 34px;
            box-sizing: border-box;
            height: 100%;
            padding-left: 34px !important;
            border-left: 0px;
          }

          span {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            // border-left: 1px solid #edf0f3;
            white-space: nowrap;
          }

          .el-table__expand-icon {
            min-width: 34px;
            width: 34px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            margin-right: 0px;
          }
        }
      }

      .cell {
        padding: 0 10px;
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #0e0f11;
        line-height: 16px;
      }

      &.el-table-column--selection {
        .cell {
          width: 100%;
          padding: 0px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-account-mapping-chartOfAccount-popper.el-popper {
  .el-select-dropdown__footer {
    padding: 4px;
    height: 44px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    div {
      cursor: pointer;
      height: 36px;
      padding: 4px 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Plus Jakarta Sans';
      color: #1753eb;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      svg {
        margin-right: 10px;
      }
    }
  }
}

.elv-account-mapping-table-row__platform-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    // display: block;
    // border-radius: 50%;
  }
}

.elv-account-mapping-table-row__platform-name {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #0e0f11;
  margin-left: 10px;
}

.account-mapping-list-coa {
  display: flex;
  flex-direction: column;
  padding: 12px 0 12px 10px;

  &-status {
    display: flex;
    align-items: center;

    b {
      margin-left: 6px;
      font-weight: 500;
      color: #1e2024;
    }

    span {
      font-weight: 400;
      color: #1e2024;
    }
  }

  &-upload {
    display: flex;
    align-items: center;
    margin-top: 4px;

    svg {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      margin-left: 6px;
      color: #2f63eb;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.elv-account-mapping-table-row__platform-edit-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 30px !important ;
    border-radius: 22px !important;
  }
}
</style>
