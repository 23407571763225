<template>
  <el-popover
    ref="currencySelectRef"
    placement="bottom-start"
    trigger="click"
    popper-class="elv-ledger-opening-balance-currency-popper"
    :show-arrow="true"
    :offset="0.2"
    :disabled="props.disabled"
    @before-enter="onCheckSelectArrow(true)"
    @before-leave="onCheckSelectArrow(false)"
  >
    <template #reference>
      <div
        class="elv-ledger-opening-balance-currency-select"
        :class="{
          'is-disabled': props.disabled
        }"
      >
        <div class="elv-ledger-opening-balance-currency-select-content">
          <img
            v-if="attrs.modelValue && currentCurrencyData?.name"
            :src="currentCurrencyData?.logo"
            :alt="currentCurrencyData?.name"
          />
        </div>
        <SvgIcon name="arrow-down" width="16" height="16" :style="{ transform: arrowRotate }" />
        <div class="elv-ledger-opening-balance-currency-select__line" />
      </div>
    </template>

    <div class="elv-ledger-opening-balance-currency-dropdown-content">
      <el-select
        :teleported="false"
        filterable
        v-bind="$attrs"
        popper-class="elv-ledger-opening-balance-currency-dropdown-popper"
        placeholder="Search"
        remote
        reserve-keyword
        :loading="currencyLoading"
        :remote-method="remoteCurrencyMethod"
        @change="onChangeSelect"
      >
        <template #prefix>
          <SvgIcon name="select-search" width="14" height="14" />
        </template>
        <el-option
          v-for="item in currencyOptions"
          :key="item.symbol"
          class="elv-ledger-opening-balance-currency-dropdown-filter-item"
          :value="item.symbol"
          :label="item.showSymbol"
        >
          <img :src="item.logo" :alt="item.name" />
          {{ item.showSymbol }}
        </el-option>
      </el-select>
    </div>
  </el-popover>
</template>

<script setup lang="ts">
import ConfigApi from '@/api/ConfigApi'
import { ElPopover } from 'element-plus'
import { CurrencyItemType } from '#/ReportsTypes'
import { find, cloneDeep, uniqBy } from 'lodash-es'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  currencyTypes: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  currenciesRecommendData: {
    type: Array as PropType<CurrencyItemType[]>,
    default: () => []
  }
})

const route = useRoute()
const attrs: any = useAttrs()
const emit = defineEmits(['onChangeCurrency'])

const arrowRotate = ref('rotate(0deg)')
const currencyLoading = ref(false)
const searchValue = ref('')
const currencySelectRef = ref<InstanceType<typeof ElPopover>>()
const currenciesData = ref<CurrencyItemType[]>([])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currencyOptions = computed(() => {
  if (!searchValue.value) {
    return props.currenciesRecommendData
  }
  if (props.currencyTypes.length === 1) {
    return props.currencyTypes?.[0] === 'FIAT'
      ? currenciesData.value.filter((item) => item.type === 'FIAT')
      : currenciesData.value.filter((item) => item.type === 'CRYPTO')
  }
  return currenciesData.value
})

const currenciesCommonData = computed(() => {
  const list = [...cloneDeep(props.currenciesRecommendData), ...cloneDeep(currenciesData.value)]
  return uniqBy(list, 'underlyingCurrencyId')
})

const currentCurrencyData = computed(() => {
  let data: any = {}
  data = find(currenciesCommonData.value, { symbol: attrs.modelValue }) || {}
  return data
})

const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    searchValue.value = query
    try {
      currencyLoading.value = true
      const params = {
        recommend: false,
        keywords: [query],
        entityId: entityId.value,
        type: props.currencyTypes.map((item: any) => item)
      }
      const { data } = await ConfigApi.searchCurrencyList(params)
      currenciesData.value = data
    } catch (error) {
      console.log(error)
      currenciesData.value = []
    } finally {
      currencyLoading.value = false
    }
  } else {
    searchValue.value = ''
    currenciesData.value = props.currenciesRecommendData
  }
}

const onCheckSelectArrow = (status: boolean) => {
  arrowRotate.value = status ? 'rotate(-180deg)' : 'rotate(0deg)'
}

const onChangeSelect = () => {
  currencySelectRef.value?.hide()
  arrowRotate.value = 'rotate(0deg)'
  emit('onChangeCurrency')
}
</script>

<style lang="scss">
.elv-ledger-opening-balance-currency-popper.el-popover {
  width: fit-content !important;
  padding: 0px !important;
  height: fit-content;
}

.el-popper.elv-ledger-opening-balance-currency-dropdown-popper {
  display: block !important;
  background-color: #fff;
  border-radius: 0px;
  border: 0px;
  height: 160px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: none;
  transform: translateY(0px);
  position: unset !important;

  .el-select-dropdown__wrap {
    max-height: 160px;
  }

  &.el-select-dropdown {
    border-radius: 0;
    height: 160px;

    :first-child {
      &.is-empty {
        display: none;
      }
    }
  }
}

.elv-ledger-opening-balance-currency-select {
  display: flex;
  padding: 4px 0px 4px 8px;
  box-sizing: border-box;
  height: 44px;
  width: 48px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  border: 1px solid #dde1e6;
  border-right: 0px;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #f9fafb;
  transition: all 0.2s;
  transition-property: border, box-shadow;

  .elv-ledger-opening-balance-currency-select-content {
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: block;
    }
  }

  svg {
    transition: transform 0.3s;
  }

  &.is-disabled {
    cursor: not-allowed;
  }

  .elv-ledger-opening-balance-currency-select__line {
    width: 1px;
    height: 42px;
    background: #edf0f3;
    margin-left: 1px;
  }
}

.elv-ledger-opening-balance-currency-dropdown-content {
  width: 285px;
  height: 192px;

  .el-select {
    .el-select__wrapper {
      height: 32px;
      box-shadow: none !important;
      border: 0px !important;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 1px solid #dde1e6 !important;
    }

    .elv-ledger-opening-balance-currency-dropdown-filter-item {
      color: #1e2024;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &.is-selected {
        color: #1e2024;
        background-color: #edf0f3;
      }
    }

    .el-select__suffix .el-select__icon {
      display: none;

      &.is-reverse {
        display: none !important;
      }
    }
  }
}
</style>
