<template>
  <el-dialog
    v-model="showDeleteSourceDialog"
    width="30%"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-chartOfAccount-delete-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-chartOfAccount-delete-dialog-header__title">
        {{ t('title.deleteAccount') }}
      </h4>
    </template>
    <div v-if="isCheckingAccountStatus" class="elv-chartOfAccount-delete-dialog-checking">
      <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
      {{ t('title.checkingAccountStatus') }}
    </div>
    <template v-else>
      <template v-if="unableDelete">
        <p class="elv-chartOfAccount-delete-dialog-unable__title">
          {{ t('message.unableDeleteChartOfAccountInfo', { account: props.itemData?.name }) }}
        </p>
        <ul>
          <li v-show="checkStatusData.JOURNAL_TYPE">{{ t('message.journalTypesAssociatedWithThisAccount') }}</li>
          <li v-show="checkStatusData.JOURNAL_ENTRY">{{ t('message.journalsAssociatedWithThisAccount') }}</li>
          <li v-show="checkStatusData.AUTOMATION">{{ t('message.automationsAssociatedWithThisAccount') }}</li>
          <li v-show="checkStatusData.TREASURY_ACCOUNT_MAPPING">
            {{ t('message.treasuryAccountsAssociatedWithThisAccount') }}
          </li>
        </ul>
      </template>
      <template v-else>
        <i18n-t
          keypath="message.deletedChartOfCountInfo"
          tag="p"
          scope="global"
          class="elv-chartOfAccount-delete-dialog-content__title"
        >
          <template #account>
            <span>{{ props.itemData?.name }}</span>
          </template>
        </i18n-t>
        <p class="elv-chartOfAccount-delete-dialog-content__placeholder">
          {{ t('message.pleaseEnterChartOfAccountName') }}
        </p>
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" @submit.prevent>
          <el-form-item prop="chartOfAccountName">
            <el-input ref="deleteInputRef" v-model="ruleForm.chartOfAccountName" />
          </el-form-item>
        </el-form>
      </template>
    </template>

    <template #footer>
      <el-button
        v-if="!unableDelete || isCheckingAccountStatus"
        type="info"
        round
        class="elv-chartOfAccount-delete-dialog-footer__cancel"
        @click="onCancelDelete"
        >{{ t('button.cancel') }}</el-button
      >
      <el-button
        v-if="!isCheckingAccountStatus"
        type="primary"
        round
        class="elv-chartOfAccount-delete-dialog-footer__delete"
        :loading="deleteLoading"
        @click="onDeleteSource"
        >{{ unableDelete ? t('button.close') : t('button.delete') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { trim, isEmpty } from 'lodash-es'
import type { FormInstance, FormRules } from 'element-plus'

const props = defineProps({
  itemData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()

const ruleForm = reactive({
  chartOfAccountName: ''
})
const deleteLoading = ref(false)
const showDeleteSourceDialog = ref(false)
const isCheckingAccountStatus = ref(true)
const unableDelete = ref(true)
const deleteInputRef = ref()
const checkStatusData: any = ref({})
const ruleFormRef = ref<FormInstance>()
const emit = defineEmits(['onResetList'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const rules = reactive<FormRules>({
  chartOfAccountName: [
    {
      required: true,
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseEnterChartOfAccountName')}.`)
        }
        if (trim(value) !== trim(props.itemData?.name)) {
          return new Error(`${t('message.enterChartOfAccountName')}.`)
        }
        return true
      }
    }
  ]
})
// 切换删除弹窗展示
const onCheckDeleteDialog = () => {
  showDeleteSourceDialog.value = !showDeleteSourceDialog.value
}

const onCancelDelete = () => {
  ruleFormRef.value?.resetFields()
  ruleForm.chartOfAccountName = ''
  isCheckingAccountStatus.value = false
  unableDelete.value = true
  onCheckDeleteDialog()
}

const onDeleteSource = async () => {
  if (unableDelete.value) {
    onCancelDelete()
    return
  }
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        deleteLoading.value = true
        await LedgerApi.deleteChartOfAccount(entityId.value, props.itemData?.chartOfAccountId)
        deleteLoading.value = false
        emit('onResetList')
        ElMessage.success(t('message.success'))
        onCancelDelete()
      } catch (error: any) {
        deleteLoading.value = false
        ElMessage.error(error?.message)
        console.log(error)
      }
    }
  })
}

defineExpose({ onCheckDeleteDialog })

watch([() => props.itemData, () => showDeleteSourceDialog.value], async () => {
  if (showDeleteSourceDialog.value && !isEmpty(props.itemData)) {
    isCheckingAccountStatus.value = true
    try {
      const { data } = await LedgerApi.checkChartOfAccountUsage(entityId.value, props.itemData?.chartOfAccountId)
      checkStatusData.value = data
      unableDelete.value = !Object.values(data).every((item) => !item)
    } catch (error) {
      console.log(error)
    } finally {
      isCheckingAccountStatus.value = false
    }
  }
})
</script>

<style lang="scss">
.elv-chartOfAccount-delete-dialog {
  width: 480px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;

    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;

    .elv-chartOfAccount-delete-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 23px 24px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-chartOfAccount-delete-dialog-checking {
      width: 100%;
      height: 100px;
      box-sizing: border-box;
      padding: 17px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      img {
        display: block;
        width: 32px;
        height: 32px;
        animation: loading-rotate 2s linear infinite;
        margin-bottom: 16px;
      }
    }

    .elv-chartOfAccount-delete-dialog-unable__title {
      margin-top: 4px;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .elv-chartOfAccount-delete-dialog-content__title {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-break: break-word;

      span {
        font-weight: 700;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      li {
        margin-left: 20px;
        list-style: disc;
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .elv-chartOfAccount-delete-dialog-content__placeholder {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .el-input {
      width: 432px;
      height: 44px;
      border-radius: 4px;
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        border: 1px solid #4c7ad2;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
      }

      &.is_focus {
        border: 1px solid #4c7ad2;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
      }
    }

    .el-form-item {
      margin-bottom: 0;

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-chartOfAccount-delete-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
    }

    .elv-chartOfAccount-delete-dialog-footer__delete {
      margin-left: 20px;
    }
  }
}
</style>
