<template>
  <div>
    <Header
      ref="chartOfAccountHeaderRef"
      type="chartOfAccount"
      :title="t('menus.ledger')"
      updated-on=""
      :show-regenerate="false"
      :show-regenerate-button="false"
    />

    <HeaderTabs />

    <chartOfAccountScreening
      table-type="chartOfAccount"
      :default-list-type="defaultListType"
      @onAddNew="onAddNewChartOfAccount"
      @onCheckTableExpand="onCheckTableExpand"
      @onOpenOpeningBalanceDialog="onOpenOpeningBalanceDialog"
      @onOpenTreasuryAccountMappingDialog="onCheckTreasuryAccountMappingDialog"
      @onOpenChartOfAccountMappingDialog="onOpenChartOfAccountMappingDialog"
    />
  </div>

  <ListTable
    ref="tableRef"
    :table-height="tableHeight"
    :table-data="tableData"
    :table-loading="tableLoading"
    @onResetList="getChartOrAccountTableData"
    @onEdit="onEditChartOfAccount"
  />

  <COADialog
    ref="COADialogRef"
    :chart-or-account-list="chartOrAccountData.list"
    :model="chartOfAccountDialogModel"
    :current-data="currentChartOrAccount"
    @onResetList="getChartOrAccountTableData"
  />

  <TreasuryAccountMappingDialog ref="treasuryAccountMappingDialogRef" :chartOfAccountList="chartOrAccountData.list" />

  <ChartOfAccountMappingDialog ref="chartOfAccountMappingDialogRef" />

  <OpeningBalanceDialog ref="openingBalanceDialogRef" :chart-or-account-list="chartOrAccountData.list" />
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import Header from '../../components/Header.vue'
import ListTable from './components/ListTable.vue'
import COADialog from './components/COADialog.vue'
import HeaderTabs from '../components/HeaderTabs.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useTransactionStore } from '@/stores/modules/transactions'
import chartOfAccountScreening from '../components/LedgerScreening.vue'
import OpeningBalanceDialog from './components/OpeningBalanceDialog.vue'
import TreasuryAccountMappingDialog from './components/TreasuryAccountMappingDialog.vue'
import ChartOfAccountMappingDialog from '../components/ThirdpartyAccountMapping/ChartOfAccountMappingDialog.vue'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()
const chartOfAccountHeaderRef = ref()
const tableRef = ref()
const COADialogRef = ref()
const openingBalanceDialogRef = ref()
const treasuryAccountMappingDialogRef = ref()
const chartOfAccountMappingDialogRef = ref()
const loading = ref(false)
const tableLoading = ref(true)
const chartOfAccountDialogModel = ref('add')
const defaultListType = ref('EXPAND')
const currentChartOrAccount: any = ref({})
const chartOrAccountData: any = ref({
  totalCount: 0,
  list: []
})

// eslint-disable-next-line no-unused-vars
const tableHeight = computed(() => {
  return `calc(100vh - 210px)`
})

const onCheckTableExpand = (status: string) => {
  defaultListType.value = status
  tableRef.value?.onCheckTableExpand(defaultListType.value)
}

const tableData = computed(() => {
  const formatData = (data: any, parentAccountId: any) => {
    return data?.map((item: any) => {
      item.parentAccountId = parentAccountId
      if (item?.list) {
        formatData(item?.list, item.code)
      }
      return item
    })
  }
  return formatData(chartOrAccountData.value.list, null)
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onAddNewChartOfAccount = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  chartOfAccountDialogModel.value = 'add'
  COADialogRef.value?.onCheckCoaDialog()
}

const onCheckTreasuryAccountMappingDialog = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  treasuryAccountMappingDialogRef.value?.onCheckTreasuryAccountMappingDialog()
}

const onOpenChartOfAccountMappingDialog = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }

  chartOfAccountMappingDialogRef.value?.onCheckDialog()
}

const onOpenOpeningBalanceDialog = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  openingBalanceDialogRef.value?.onCheckOpeningBalanceDialog()
}

const onEditChartOfAccount = (row: any) => {
  chartOfAccountDialogModel.value = 'edit'
  currentChartOrAccount.value = row
  COADialogRef.value?.onCheckCoaDialog()
}

const getChartOrAccountTableData = async () => {
  try {
    tableLoading.value = true
    const { data } = await LedgerApi.getChartOfAccountList(entityId.value)
    chartOrAccountData.value.list = data
    console.log(data)
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

watch(
  () => route,
  async (newValue) => {
    try {
      if (newValue.name === 'entity-ledger-chart-of-account') {
        loading.value = true
        getChartOrAccountTableData()
        transactionStore.fetchTransactionCurrencyList(entityId.value)
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  },
  { immediate: true }
)
</script>
